import Vue from 'vue';
import Vuex from 'vuex';
import { auth, getKlantData, getOpenInzamelingen, getOpgehaaldeInzamelingen } from '@/api';
import { apiLogout } from './api';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    tokenCreated: localStorage.getItem('tokenCreated') || '',
    klantData: null,
    klantDataUpdateTime: null,
    openInzamelingen: null,
    opgehaaldeInzamelingen: null,
  },
  mutations: {
    authSuccess(state, tokenCreated) {
      state.tokenCreated = tokenCreated;
    },
    logout(state) {
      state.tokenCreated = '';
    },
    setKlantData(state, klantData) {
      state.klantData = klantData;
      state.klantDataUpdateTime = new Date().getTime();
    },
    setOpenInzamelingen(state, openInzamelingen) {
      state.openInzamelingen = openInzamelingen;
    },
    setOpgehaaldeInzamelingen(state, opgehaaldeInzamelingen) {
      state.opgehaaldeInzamelingen = opgehaaldeInzamelingen;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        auth(user)
          .then((resp) => {
            const now = new Date();
            const tokenCreated = now.getTime();
            localStorage.setItem('tokenCreated', tokenCreated);
            commit('authSuccess', tokenCreated);
            resolve(resp);
          })
          .catch((err) => {
            localStorage.removeItem('tokenCreated');
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        apiLogout()
          .then(() => {
            commit('logout');
            localStorage.removeItem('tokenCreated');
            resolve();
          })
          .catch(() => {
            commit('logout');
            localStorage.removeItem('tokenCreated');
            resolve();
          });
      });
    },
    updateKlantData({ commit, state }) {
      // only refresh if klantData is expired
      if (state.klantDataUpdateTime) {
        let lastSec = parseInt(state.klantDataUpdateTime / 1000);
        let nowSec = parseInt(new Date().getTime() / 1000);
        if (nowSec - lastSec < 300) {
          // data refresh < 300 seconds ago
          return;
        }
      }
      getKlantData().then((data) => {
        commit('setKlantData', data);
      });
    },
    updateOpenInzamelingen({ commit }) {
      getOpenInzamelingen().then((data) => {
        commit('setOpenInzamelingen', data);
      });
    },
    updateOpgehaaldeInzamelingen({ commit }) {
      getOpgehaaldeInzamelingen().then((data) => {
        commit('setOpgehaaldeInzamelingen', data);
      });
    },
  },
  modules: {},
  getters: {
    isLoggedIn: (state) => !!state.tokenCreated,
    klantData: (state) => state.klantData,
    openInzamelingen: (state) => state.openInzamelingen,
    opgehaaldeInzamelingen: (state) => state.opgehaaldeInzamelingen,
  },
});
