<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <LoginForm />
        <v-row justify="center">
          <v-col cols="12" md="6" sm="8"> Neem contact met ons op als u nog geen klantnummer/pincode heeft. </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="6" sm="8">
            <v-btn acolor="secondary" href="https://www.granuband.com/" target="_blank">
              Naar de website van Granuband
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from '@/components/LoginForm';

export default {
  name: 'Login',

  components: {
    LoginForm,
  },
};
</script>

<style scoped></style>
