<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" sm="8">
      <v-card class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Inloggen</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="login">
            <v-text-field
              v-model="klantnummer"
              :rules="klantnummerRules"
              label="Klantnummer"
              prepend-icon="mdi-account"
            ></v-text-field>

            <v-text-field
              v-model="pincode"
              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="pincodeRules"
              :type="show1 ? 'text' : 'password'"
              label="Pincode"
              prepend-icon="mdi-lock"
              @click:append="show1 = !show1"
            ></v-text-field>
          </v-form>
          <v-alert v-if="errorMessage" dense text type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" color="primary" @click="login"> Inloggen </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      klantnummer: '',
      klantnummerRules: [(v) => !!v || 'Klantnummer is verplicht'],
      pincode: '',
      pincodeRules: [(v) => !!v || 'Pincode is verplicht'],
      errorMessage: null,
      valid: false,
      show1: false,
    };
  },
  methods: {
    login: function () {
      let klantnummer = this.klantnummer;
      let pincode = this.pincode;
      this.$store
        .dispatch('login', { klantnummer, pincode })
        .then(() => this.$router.push('/'))
        .catch((err) => {
          this.errorMessage = err;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        });
    },
  },
};
</script>

<style scoped></style>
