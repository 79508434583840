<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="6" sm="8">
      <v-alert v-if="resultaat" type="success">
        {{ resultaat }}
      </v-alert>
      <v-card v-if="!resultaat && klantData" class="elevation-12">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>Nieuwe inzameling</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid" @submit.prevent="login">
            <v-text-field
              v-if="klantData.adressen.length <= 1"
              v-model="klantData.adressen[0].adres"
              label="Afhaaladres"
              prepend-icon="mdi-home-city-outline"
              disabled
            ></v-text-field>
            <v-select
              v-if="klantData.adressen.length > 1"
              v-model="adres"
              :rules="requiredRule"
              :items="klantData.adressen"
              item-value="id"
              item-text="adres"
              label="Afhaaladres"
              prepend-icon="mdi-home-city-outline"
            ></v-select>
            <v-text-field
              v-if="klantData.types.length <= 1"
              v-model="klantData.types[0].naam"
              label="Band"
              prepend-icon="mdi-alpha-b-circle-outline"
              disabled
            ></v-text-field>
            <v-select
              v-if="klantData.types.length > 1"
              v-model="type"
              :rules="requiredRule"
              :items="klantData.types"
              item-value="id"
              item-text="naam"
              label="Band"
              prepend-icon="mdi-alpha-b-circle-outline"
            ></v-select>
            <v-text-field
              v-if="klantData.hoeveelheidSpecificeren"
              v-model="hoeveelheid"
              :rules="requiredRule"
              label="Specificeer de hoeveelheid"
              prepend-icon="mdi-calculator"
            ></v-text-field>
            <v-text-field
              v-model="contactpersoon"
              label="Contactpersoon"
              prepend-icon="mdi-account"
              :rules="contactRules"
            ></v-text-field>
            <v-text-field
              v-model="contacttelnr"
              label="Contacttelefoonnr."
              prepend-icon="mdi-cellphone-basic"
              :rules="contactRules"
            ></v-text-field>
            <v-text-field v-model="opmerkingen" label="Opmerkingen" prepend-icon="mdi-comment"></v-text-field>
            <v-checkbox
              v-model="akkoord"
              :rules="requiredRule"
              label="Ik ga akkoord met de algemene leveringsvoorwaarden"
            ></v-checkbox>
          </v-form>
          <v-alert v-if="errorMessage" dense text type="error">
            {{ errorMessage }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="!valid" color="primary" @click="verstuurOpdracht"> Verstuur opdracht </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { nieuweInzameling } from '@/api';

export default {
  name: 'InzamelingForm',
  data() {
    return {
      adres: '',
      type: '',
      hoeveelheid: '',
      contactpersoon: '',
      contacttelnr: '',
      opmerkingen: '',
      akkoord: '',
      requiredRule: [(v) => !!v || 'Veld is verplicht'],
      contactRules: [(v) => v.length <= 50 || 'Maximaal 50 tekens'],
      errorMessage: null,
      valid: false,
      resultaat: null,
    };
  },
  computed: {
    klantData: function () {
      return this.$store.getters.klantData;
    },
  },
  created: function () {
    this.$store.dispatch('updateKlantData');
  },

  methods: {
    verstuurOpdracht: function () {
      let adres = this.adres;
      let type = this.type;
      let hoeveelheid = this.hoeveelheid;
      let contactpersoon = this.contactpersoon;
      let contacttelnr = this.contacttelnr;
      let opmerkingen = this.opmerkingen;
      let akkoord = this.akkoord;
      let data = { adres, type, hoeveelheid, contactpersoon, contacttelnr, opmerkingen, akkoord };

      nieuweInzameling(data)
        .then((resp) => {
          this.resultaat = resp.result;
        })
        .catch((err) => {
          this.errorMessage = err;
          setTimeout(() => {
            this.errorMessage = '';
          }, 3000);
        });
    },
  },
};
</script>

<style scoped></style>
