<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <InzamelingForm />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InzamelingForm from '@/components/InzamelingForm';

export default {
  name: 'Inzameling',

  components: {
    InzamelingForm,
  },
};
</script>

<style scoped></style>
