<template>
  <v-container>
    <v-row v-if="opgehaaldeInzamelingen" align="center" justify="center">
      <v-col lg="6" md="12" sm="12">
        <h2>Overzicht inzamelingen</h2>
        <v-simple-table>
          <thead>
            <tr>
              <th>Datum</th>
              <th>Adres</th>
              <th>Type</th>
              <th>Gewicht (kg.)</th>
              <th>Stuks</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="inzameling in opgehaaldeInzamelingen.recent.inzamelingen" :key="inzameling.index">
              <td>{{ inzameling.datum }}</td>
              <td>{{ inzameling.adres }}</td>
              <td>{{ inzameling.type }}</td>
              <td class="text-right">{{ inzameling.gewicht }}</td>
              <td class="text-right">{{ inzameling.stuks }}</td>
            </tr>
          </tbody>
        </v-simple-table>

        <div v-for="kwartaal in opgehaaldeInzamelingen.kwartalen" :key="kwartaal.index">
          <br />
          <h4>Kwartaaloverzicht {{ kwartaal.periode }}</h4>

          <v-simple-table class="totalen">
            <thead>
              <tr>
                <th>Type</th>
                <th></th>
                <th></th>
                <th class="text-right">Totaal gewicht (kg.)</th>
                <th class="text-right">Stuks</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="totaal in kwartaal.totalen" :key="totaal.index">
                <td>{{ totaal.type }}</td>
                <td></td>
                <td></td>
                <td class="text-right">{{ totaal.gewicht }}</td>
                <td class="text-right">{{ totaal.stuks }}</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <th>Datum</th>
                <th>Adres</th>
                <th>Type</th>
                <th class="text-right">Gewicht (kg.)</th>
                <th class="text-right">Stuks</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="inzameling in kwartaal.inzamelingen" :key="inzameling.index">
                <td>{{ inzameling.datum }}</td>
                <td>{{ inzameling.adres }}</td>
                <td>{{ inzameling.type }}</td>
                <td class="text-right">{{ inzameling.gewicht }}</td>
                <td class="text-right">{{ inzameling.stuks }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Geschiedenis',

  computed: {
    opgehaaldeInzamelingen: function () {
      return this.$store.getters.opgehaaldeInzamelingen;
    },
  },

  created: function () {
    this.$store.dispatch('updateOpgehaaldeInzamelingen');
  },
};
</script>

<style scoped>
.totalen {
  width: 80%;
}
</style>
