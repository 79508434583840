<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="12" sm="12">
      <div v-if="openInzamelingen">
        <h3>Openstaande inzamelopdrachten:</h3>
        <v-simple-table>
          <thead>
            <tr>
              <th>Adres</th>
              <th>Type</th>
              <th>Datum</th>
              <th>Plandatum*</th>
            </tr>
          </thead>
          <tr v-for="inzameling in openInzamelingen" :key="inzameling.index">
            <td>{{ inzameling.adres }}</td>
            <td>{{ inzameling.type }}</td>
            <td>{{ inzameling.datum }}</td>
            <td>{{ inzameling.plandatum }}</td>
          </tr>
        </v-simple-table>
        <p><br /><i>* De plandatum is altijd onder voorbehoud</i></p>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'OpenInzamelingen',

  data: function () {
    return {
      timeHidden: null,
    };
  },

  computed: {
    openInzamelingen: function () {
      return this.$store.getters.openInzamelingen;
    },
  },
  created: function () {
    this.$store.dispatch('updateOpenInzamelingen');

    document.addEventListener('visibilitychange', () => {
      let state = document.visibilityState;
      if (state === 'hidden') {
        this.timeHidden = new Date().getTime();
      } else if (state === 'visible' && this.timeHidden) {
        let lastSec = parseInt(this.timeHidden / 1000);
        let nowSec = parseInt(new Date().getTime() / 1000);
        if (nowSec - lastSec > 300) {
          this.$store.dispatch('updateOpenInzamelingen');
        }
      }
    });
  },
};
</script>

<style scoped></style>
