<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8" v-if="isLoggedIn">
        <v-img src="@/assets/home.jpg"></v-img>
        <h2>
          Welkom <span v-if="klantData">{{ klantData.klant }}</span>
        </h2>
        <br />

        <v-btn to="/inzameling">Maak een nieuwe inzamelopdracht</v-btn>
        <br /><br />

        <OpenInzamelingen />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OpenInzamelingen from '@/components/OpenInzamelingen';

export default {
  name: 'Home',
  components: {
    OpenInzamelingen,
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    klantData: function () {
      return this.$store.getters.klantData;
    },
  },
  created: function () {
    if (this.isLoggedIn) {
      this.$store.dispatch('updateKlantData');
    }
  },
};
</script>
